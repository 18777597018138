import React from "react";
import { Table } from "antd";

const AccessibleTable = (props) => {
  const pagination  = (totalRows) =>  {
    return {
      pageSize: totalRows ? totalRows : 5,
      total: props.rows.length,
      showSizeChanger: false,
      showQuickJumper: false,
    }
  };

  if (!props.rows) {
    return null;
  }

  return (
    <Table
      columns={props.columns}
      dataSource={props.rows.map((row, index) => ({ ...row, key: index }))}
      pagination={props.pagination ? pagination(props.pagination) : pagination}
      title={props.title}
      bordered
    />
  );
};

export default AccessibleTable;
