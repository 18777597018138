import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { Tag } from "antd";

import DefaultLayout from "../layouts/DefaultLayout";
import AccessibleTable from "../components/Table/Table";

const DashboardView = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("user");
  const [instanceDetails, setInstanceDetails] = useState([]);
  const instanceColumns = [
    {
      title: "SRL NO",
      dataIndex: "srl_no",
      key: "srl_no",
      render: (text, record) => {
        if (record.status === "Completed" || record.status === "In Progress") {
          return (
            //eslint-disable-next-line
            <a
              onClick={(e) => {
                e.preventDefault();
                handleIdClick(text, record);
              }}
              style={{
                color: "blue",
                cursor: "pointer",
              }}
            >
              {text}
            </a>
          );
        } else {
          return (
            <span
              style={{
                color: "black",
              }}
            >
              {text}
            </span>
          );
        }
      },
      width: 50,
    },
    {
      title: "FOLDERNAME",
      dataIndex: "foldername",
      key: "foldername",
      // render: (text) => <Tag color="#de2c2c">{text}</Tag>,
      width: 50,
    },
    {
      title: "EXTRACTION STATUS",
      dataIndex: "status",
      key: "status",
      // render: (text) => <Tag color="#de2c2c">{text}</Tag>,
      width: 50,
    },
    {
      title: "NUMBER OF DOCUMENTS",
      dataIndex: "total_number_of_documents",
      key: "total_no_of_documents",
      // render: (text) => <Tag color="#de2c2c">{text}</Tag>,
      width: 50,
    },
    { title: "SERVICE", dataIndex: "service", key: "service", width: 50 },
  ];

  if (username === "supervisor") {
    instanceColumns.push({
      title: "USERNAME",
      dataIndex: "username",
      key: "username",
      width: 50,
    });
  }

  const handleIdClick = (id, record) => {
    const folderName = record.foldername;
    navigate("/details", { state: { folderName: folderName } });
  };

  const addInvoice = () => {
    navigate("/extract");
  };

  const fetchData = async () => {
    try {
      let isProcessingCompleted = true;
      const formData = new FormData();
      formData.append("username", localStorage.getItem("user"));
      const response = await axios.post(
        "https://1074-103-121-27-178.ngrok-free.app/api/invoice/get-user-instance-details",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
          processData: false,
          contentType: false,
        }
      );

      const data = response.data;

      // Determine the status for each item and add it
      const updatedInstanceDetails = data.user_batches_info.map(
        (item, index) => {
          let status = "";

          // Your condition to determine the status
          if (
            item.total_number_of_documents ===
            item.total_number_of_documents_failed_to_process
          ) {
            status = "Failed";
          } else if (
            item.total_number_of_documents ===
            item.total_number_of_documents_successfully_processed +
              item.total_number_of_documents_failed_to_process
          ) {
            status = "Completed";
          } else if (
            item.total_number_of_documents_successfully_processed +
              item.total_number_of_documents_failed_to_process ===
            0
          ) {
            status = "Not Started";
            isProcessingCompleted = false;
          } else if (
            item.total_number_of_documents >
            item.total_number_of_documents_successfully_processed +
              item.total_number_of_documents_failed_to_process
          ) {
            status = "In Progress";
            isProcessingCompleted = false;
          }
          // Add the status property to the item
          return { ...item, status, srl_no: index + 1 };
        }
      );

      // Set the updated data to the state
      setInstanceDetails(updatedInstanceDetails);

      if (!isProcessingCompleted) {
        setTimeout(fetchData, 5000);
      }

      // console.log("Instance details with status: ", updatedInstanceDetails);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <DefaultLayout
      profileName={localStorage.getItem("user")}
      showProfile={true}
    >
      <div className="flex flex-col items-center">
        <h1 className="text-5xl font-bold">Dashboard</h1>
        <button
          className="py-6 px-10 rounded-lg bg-red200 text-white ml-auto mb-4"
          onClick={addInvoice}
        >
          Add Invoice
        </button>
      </div>
      <AccessibleTable
        rows={instanceDetails}
        columns={instanceColumns}
        pagination={5}
      />
    </DefaultLayout>
  );
};

export default DashboardView;
