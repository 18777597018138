import React from "react";
import { Routes, Route } from "react-router-dom";
import HomeView from "../views/HomeView";
import Dashboard from "../views/DashboardView";
import ExtractionView from "../views/ExtractionView";
import LoginView from "../views/LoginView";
import AddInvoiceView from "../views/AddInvoiceView";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/login" element={<LoginView />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/extract" element={<AddInvoiceView />} />
      <Route path="/details" element={<ExtractionView />} />
    </Routes>
  );
}

export default AppRoutes;
