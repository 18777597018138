import React from "react";
import { useState } from "react";

import { Tag, Tabs } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";

import AccessibleTable from "../Table/Table";
import ExcelDownloadButton from "../Download/ExportExcel";
import ProgressBarLineBatchProcessing from "../ProgressBar/ProgressBar";

const FileDetails = (props) => {
  const [currency, setCurrency] = useState("$"); // Set the default value

  const handleCurrencyChange = (value) => {
    setCurrency(value);
  };

  const incrementKey = () => {
    props.incrementKey();
  };

  const decrementKey = () => {
    props.decrementKey();
  };

  const extractedWordsColumns = [
    {
      title: "WORD",
      dataIndex: "type",
      key: "type",
      render: (text) => <Tag color="geekblue">{text}</Tag>,
      width: 100,
    },
    {
      title: "EXTRACTED VALUE",
      dataIndex: "extracted_value",
      key: "extracted_value",
      width: 100,
    },
    {
      title: "CONFIDENCE",
      dataIndex: "confidence",
      key: "confidence",
      width: 100,
    },
  ];

  const DocsInfoColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <Tag color="orange">{text}</Tag>,
      width: 100,
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: 100,
    },
  ];

  let { words, info, jobstatus, id } = props.result;
  let processingCompleted = props.processing;
  words = JSON.parse(words);
  info = JSON.parse(info);

  let data = { info }.info;
  let wordExtractedData = [];
  let batchDocInfo = [];

  const conversionRates = {
    "$": {
      "£": 0.52584,
      "€": 0.60464,
    },
    "€": {
      "$": 1.6523,
      "£": 0.86938,
    },
    "£": {
      "$": 1.89886,
      "€": 1.14941,
    },
  };
  
  function convertCurrencies(text, targetCurrency) {
    const currencyRegex = /([$£€])\s?(\d{1,3}(?:,\d{3})*(?:\.\d{1,2})?)/g;
  
    const convertedText = text.replace(currencyRegex, (match, currencySymbol, amount) => {
      const sourceCurrency = currencySymbol;
  
      // Remove commas from the amount and parse it as a float
      const parsedAmount = parseFloat(amount.replace(/,/g, ''));
  
      if (!isNaN(parsedAmount) && conversionRates[sourceCurrency] && conversionRates[sourceCurrency][targetCurrency]) {
        const exchangeRate = conversionRates[sourceCurrency][targetCurrency];
        const convertedAmount = (parsedAmount * exchangeRate).toFixed(2);
        return `${targetCurrency}${convertedAmount}`
      }
      return match; // Return the original text if no conversion rate is found or if the amount is invalid
    });
    return convertedText;
  }

  function findCurrencySymbol(text) {
    const currencySymbolPattern = /[$€£]/;
    const matches = text.match(currencySymbolPattern);
    if (matches) {
      return matches[0]; // Return the first match
    }
    return null; // If no match found
  }

  //Adding data for word extraction table
  const excludable_words = [];

  // Step 1: Find keys in 'data' that match keys in 'words' and store them in 'excludable_words'
  for (let key2 in words) {
    for (let key3 in data) {
      if (key3.includes(key2)) {
        excludable_words.push(key3);
      }
    }
  }

  // Step 2: Process keys in 'data' that are not in 'excludable_words'
  for (let key4 in data) {
    if (!excludable_words.includes(key4)) {
      const [extractedValue, confidence] = data[key4];
      let currentCurrency = findCurrencySymbol(extractedValue);
      if (currentCurrency && currentCurrency !== currency) {
        let updatedValue = convertCurrencies(extractedValue, currency);
        wordExtractedData.push({
          type: key4,
          extracted_value: updatedValue,
          confidence: confidence.toFixed(2),
        });
      } else {
        wordExtractedData.push({
          type: key4,
          extracted_value: extractedValue,
          confidence: confidence.toFixed(2),
        });
      }
    }
  }

  batchDocInfo.push({
    type: "Current File Number",
    number: props.fileNumber,
  });

  for (let key4 in props.fileInfo) {
    batchDocInfo.push({
      type: key4,
      number: props.fileInfo[key4],
    });
  }

  batchDocInfo.push({
    type: "Text Extraction Status",
    number: props.processing ? "Completed" : "In progress",
  });

  return (
    <div>
      {jobstatus === "SUCCEEDED" && (
        <div className="flex">
          <div className="w-full h-[calc(100vh-50px)]">
            <iframe
              style={{ width: "100%", height: "85%" }}
              src={props.result.payslip_url}
              alt="Invoice"
              title="Invoice"
            >
              Uploaded Invoice
            </iframe>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className={`mr-4 mt-4 cursor-pointer p-2 leading-[14px] text-white font-Inter rounded bg-red200 ${
                  props.buttonDisabled.startDisabled ? "opacity-50" : ""
                }`}
                id="prevBtn"
                onClick={decrementKey}
                disabled={props.buttonDisabled.startDisabled}
              >
                Prev
              </button>

              <button
                className={`mr-4 mt-4 cursor-pointer p-2 leading-[14px] text-white font-Inter rounded bg-red200 ${
                  props.buttonDisabled.endDisabled ? "opacity-50" : ""
                }`}
                id="prevBtn"
                onClick={incrementKey}
                disabled={props.buttonDisabled.endDisabled}
              >
                Next
              </button>
            </div>
          </div>
          <div className="w-[701px] flex-grow-0 flex-shrink-0 basis-[701px] border-l border-solid border-border100">
            <div className="pt-2 pb-10 bg-white d-flex flex-column">
              {Number(props.fileInfo["Number of documents"]) > 1 && (
                <div className="ml-4 mr-2 mb-2">
                  <ProgressBarLineBatchProcessing
                    percent={
                      (Number(
                        props.fileInfo[
                          "Documents with successful text extraction"
                        ] +
                          props.fileInfo[
                            "Documents with failed text extraction"
                          ]
                      ) *
                        100) /
                      Number(props.fileInfo["Number of documents"])
                    }
                    value={
                      props.fileInfo[
                        "Documents with successful text extraction"
                      ] +
                      props.fileInfo["Documents with failed text extraction"]
                    }
                    max={props.fileInfo["Number of documents"]}
                  />
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="font-bold text-4xl leading-7.5 mb-0 pl-8 pr-4 m-4">
                  Invoice Insights
                </h2>
                <div className="form-inline mr-12">
                  <select
                    id="currency"
                    className="form-control--md"
                    defaultValue={currency}
                    onChange={(e) => handleCurrencyChange(e.target.value)}
                  >
                    <option value="$">
                      {getSymbolFromCurrency("USD")} Dollar
                    </option>
                    <option value="£">
                      {getSymbolFromCurrency("GBP")} Pound
                    </option>
                    <option value="€">
                      {getSymbolFromCurrency("EUR")} Euro
                    </option>
                    {/* Add more currency options as needed */}
                  </select>
                </div>
              </div>
              <div>
                <Tabs
                  className="tabs-Details"
                  defaultActiveKey="1"
                  items={[
                    {
                      label: "Extractions",
                      key: "1",
                      children: (
                        <div className="h-[calc(100vh-318px)] overflow-auto pb-13 pr-10">
                          <div className="extraction-table mb-[40px]">
                            <AccessibleTable
                              rows={wordExtractedData}
                              columns={extractedWordsColumns}
                              pagination={4}
                            />
                          </div>

                          <div style={{ display: "flex", gap: "8px" }}>
                            {processingCompleted && (
                              <ExcelDownloadButton
                                apiEndpoint={`https://1074-103-121-27-178.ngrok-free.app/api/invoice/download-batch-data/${id}`}
                                fileName="invoice-extractions.xlsx"
                                text="Export batch text extraction"
                                currency = {currency}
                                
                              />
                            )}

                            {id && (
                              <ExcelDownloadButton
                                apiEndpoint={`https://1074-103-121-27-178.ngrok-free.app/api/invoice/download-data/${id}`}
                                fileName={`${id}-invoice-extractions.xlsx`}
                                text="Export current file extraction"
                                currency = {currency}
                              />
                            )}
                          </div>
                        </div>
                      ),
                    },
                    {
                      label: "Processing Information",
                      key: "2",
                      children: (
                        <div className="h-[calc(100vh-318px)] overflow-auto pb-13 pr-10">
                          <div className="extraction-table mb-[40px]">
                            <AccessibleTable
                              rows={batchDocInfo}
                              columns={DocsInfoColumns}
                              pagination={4}
                            />
                          </div>
                          <div style={{ display: "flex", gap: "8px" }}>
                            {processingCompleted && (
                              <ExcelDownloadButton
                                apiEndpoint={`https://1074-103-121-27-178.ngrok-free.app/api/invoice/download-batch-data/${id}`}
                                fileName="invoice-extractions.xlsx"
                                text="Export batch text extraction"
                                currency = {currency}
                              />
                            )}

                            {id && (
                              <ExcelDownloadButton
                                apiEndpoint={`https://1074-103-121-27-178.ngrok-free.app/api/invoice/download-data/${id}`}
                                fileName={`${id}-invoice-extractions.xlsx`}
                                text="Export current file extraction"
                                currency = {currency}
                              />
                            )}
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileDetails;
