import React from "react";
import ProfileDropdown from "./DropDown/ProfileDropdown";
import AbcisLogo from "../assets/images/abcisLogo.svg";

const Header = (props) => {
  return (
    <div className="app-c-header">
      <div className="app-c-header__left">
        <h1 className="app-c-header__logo">
          {/*eslint-disable-next-line */}
          <a>
            <img src={AbcisLogo} alt="ABCis" />
          </a>
        </h1>
      </div>
      {props.showProfile && (
        <div>
          <ProfileDropdown profileName={props.profileName} />
        </div>
      )}
    </div>
  );
};

export default Header;
