import React from 'react'
import { Progress } from 'antd';

const ProgressBarLineBatchProcessing = (props) => {
  return (
    <Progress
    type='line'
    size='small'
    percent={Math.round(props.percent)}
    // format={(percent) => `${props.value} files processed out of ${props.max}`}
    strokeColor='#dc143c' //Red
    // success={{ percent: 100, strokeColor: '#73a1c9' }}
    />
  );
}

export default ProgressBarLineBatchProcessing;