import React from "react";
import Header from "../components/Header";

const DefaultLayout = ({ children, profileName, showProfile }) => {
  return (
    <div className="h-screen overflow-hidden">
      <Header showProfile={showProfile} profileName={profileName} />
      <div className="bg-primaryLight py-8 px-14 w-full h-full overflow-auto max-w-full">
        {children}
      </div>
    </div>
  );
};

export default DefaultLayout;
