import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Button } from "antd";
import { useNavigate } from "react-router-dom";
const ProfileDropdown = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleMenuClick = (e) => {
    if (e.key === "1") {
      setOpen(false);
      navigate("/dashboard");
    } else {
      localStorage.removeItem("user");
      navigate("/login");
    }
  };
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };
  const items = [
    {
      label: "Dashboard",
      key: "1",
    },
    {
      label: "Logout",
      key: "2",
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      onOpenChange={handleOpenChange}
      open={open}
    >
      <a href="#" onClick={(e) => e.preventDefault()}>
        <Space>
          <Button className="text-white" icon={<DownOutlined />}>
            {props.profileName}
          </Button>
        </Space>
      </a>
    </Dropdown>
  );
};
export default ProfileDropdown;
