import "./App.css";
import "./assets/css/global-style.scss";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "./router/AppRoutes"; 

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
