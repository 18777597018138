import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PlainLayout from "../layouts/PlainLayout";
import AlertMessage from "../components/Alert/Alert";
import AbcisLogo from "../assets/images/abcisLogo.svg";
import AbcisLogoDark from "../assets/images/abcisLogoDark.svg";
import LoginGraphics from "../assets/images/loginSideGraphics.svg";
import ExperionLogoDark from "../assets/images/experionLogoDark.svg";
import icons from "../assets/icons";

const LoginView = () => {
  // State to store the values of email and password fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertBox, setAlertBox] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const onCancel = () => {
    setAlertBox(false);
  };

  // Function to handle form submission
  const handleSubmit = (e) => {

    e.preventDefault();

    // Add your login logic here, e.g., send the data to an API
    if (email === "supervisor@invoiceinsights.com" && password === "supervisor") {
      localStorage.setItem("user", "supervisor")
      navigate("/dashboard")
    }
    else if (email === "agent@invoiceinsights.com" && password === "agent") {
      localStorage.setItem("user", "agent")
      navigate("/dashboard")
    }
    else {
      // Here replace with alert window.
      setAlertBox(true);
      setMessage("Incorrect credentials");
      console.log("Incorrect credentials")
    }

    // Clear the input values
    setEmail("");
    setPassword("");
  };

  return (
    <PlainLayout>
      {alertBox ? <AlertMessage message={message} onCancel={onCancel} /> : ""}
      <div className="app-l-content-wrap">
        <div className="app-l-login__wrap">
          <div className="app-l-login">
            <div className="app-l-login__left">
              <h1 className="app-c-login-header__logo">
                <a href="#">
                  <img
                    src={AbcisLogo}
                    alt="ABCis"
                  />
                </a>
              </h1>
              <div className="app-l-login__left__content">
                <div className="app-l-login__left__graphics">

                  <img
                    src={LoginGraphics}
                    alt="Login"
                  />
                </div>
                <div className="app-l-login__left__list">
                  <ul>
                    <li>
                      <icons.TickRound />
                      <span
                      >Exclude line items</span
                      >
                    </li>
                    <li>
                      <icons.TickRound />
                      <span
                      >Export Extractions</span
                      >
                    </li>
                    <li>
                      <icons.TickRound />
                      <span
                      >Currency based data extraction</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-l-login__right">
              <form onSubmit={handleSubmit} className="app-l-login__form">
                <h2 className="app-l-login__heading">
                  <span>Log in to</span>
                  <img
                    src={AbcisLogoDark}
                    alt="Experion"
                  />
                </h2>
                <div className="form-group">
                  <label for="email" className="form-label">Email address</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {/* <!-- Toggle class form error when error occurs --> */}
                <div className="form-group form-error">
                  <label for="password" className="form-label">Password</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {/* <!-- Error text --> */}
                  {/* <span className="form-text">Wrong password</span> */}
                </div>
                <button className="app-c-btn app-c-btn--primary w-100">
                  Login
                </button>
              </form>
              <div className="app-c-footer">
                <div className="app-c-footer__right">
                  <span className="app-c-footer__powered-by-text">Powered by</span>
                  <img
                    src={ExperionLogoDark}
                    alt="Experion"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PlainLayout>
  );
};

export default LoginView;
