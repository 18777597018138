import React, { useState } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import { Button, Spin, DatePicker, Radio } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModalTextInput from "../components/Modal/ModalTextInput";
import Header from "../components/Header";
import icons from "../assets/icons";
import AlertMessage from "../components/Alert/Alert";

import configData from "../config.json";

dayjs.extend(customParseFormat);

const AddInvoiceView = () => {
  const navigate = useNavigate();

  const S3_BUCKET = configData.S3_BUCKET;
  const REGION = configData.REGION;

  AWS.config.update({
    accessKeyId: configData.accessKeyId,
    secretAccessKey: configData.secretAccessKey,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [modal2Open, setModal2Open] = useState(false);
  const [alertBox, setAlertBox] = useState(false);
  const [upload, setUpload] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [service, setService] = useState("custom");

  const [extractableText, setExtractableText] = useState({});
  const [Message, setMessage] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(false);

  const [schedule, setSchedule] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const validateFile = (file) => {
    const validTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpeg",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const openModal2 = () => {
    setModal2Open(true);
  };

  const closeModal2 = () => {
    setModal2Open(false);
  };

  const handleExtractModalSave = (name) => {
    setExtractableText((prevValues) => ({ ...prevValues, [name]: 0 }));
  };

  const generateFolderName = () => {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 1000000);
    const name = `folder_${timestamp}_${randomNum}`;
    return name.trim();
  };

  const handleFileChange = (event) => {
    if (event.target.files) {
      setReadyForUpload(true);
      let count = 0;
      const filesArray = Array.from(event.target.files).map((file) => file);

      for (let i = 0; i < filesArray.length; i += 1) {
        if (validateFile(filesArray[i])) {
          setSelectedFiles((prevArray) => [...prevArray, filesArray[i]]);
          count += 1;
        } else {
          filesArray[i].invalid = true;
        }
      }
      toast.info(`${count} files selected`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast",
      });
    }
  };

  const uploadFile = (file, randomstr, foldername, fileNumber, totalFiles) => {
    const subfolder = "files/payslip";

    const params = {
      ACL: "private",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${subfolder}/${foldername}/${randomstr}`,
      ContentType: file.type,
    };

    myBucket
      .putObject(params)
      // .on("httpUploadProgress", () => {
      //   setProgress(Math.round((fileNumber / totalFiles) * 100));
      // })
      .send(async (err) => {
        if (err) {
          console.log(err);
          setAlertBox(true);
          setMessage(err.message);
          setProgress(false);
        } else {
          if (fileNumber === totalFiles) {
            setUpload(true);
            setProgress(false);
            setSelectedFiles([]);
            toast.success("Successfully uploaded", {
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              className: "custom-toast",
            });
          }
        }
      });
  };

  const clearFile = (event) => {
    setUpload(false);
    setSelectedFiles([]);
    setReadyForUpload(false);
    if (event) {
      event.target.value = null;
    }
  };

  const handleFileSubmit = () => {
    const foldername = generateFolderName();
    for (let i = 0; i < selectedFiles.length; i += 1) {
      setProgress(true);
      const randomstr = `${
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      }.${selectedFiles[i].name.split(".").pop()}`;
      uploadFile(
        selectedFiles[i],
        randomstr,
        foldername,
        i + 1,
        selectedFiles.length
      );
      setFolderName(foldername);
    }
  };

  const handleRemoveWord = (name) => {
    const updatedWords = { ...extractableText };
    delete updatedWords[name];
    setExtractableText(updatedWords);
  };

  const handleServiceChange = (service) => {
    setService(service);
  };

  const onCancel = () => {
    setAlertBox(false);
    if (!upload) {
      clearFile();
    }
  };

  const handleScheduleChange = (e) => {
    setSchedule(e.target.value);
    if (e.target.value === false) {
      setSelectedDateTime(null);
    }
  };

  const handleDateTimeChange = (value, dateString) => {
    const selectedDate = new Date(value);
    const formattedDate = `(${selectedDate.getFullYear()}, ${
      selectedDate.getMonth() + 1
    }, ${selectedDate.getDate()}, ${selectedDate.getHours()}, ${selectedDate.getMinutes()}, ${selectedDate.getSeconds()})`;
    setSelectedDateTime(formattedDate);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (schedule && !selectedDateTime) {
      setAlertBox(true);
      setMessage("Schedule time not given");
    } else {
      // console.log("username is: ", localStorage.getItem('user'))
      const formData = new FormData();
      formData.append("foldername", folderName);
      formData.append("service", service);
      formData.append("username", localStorage.getItem("user"));
      formData.append("conditions", "");
      formData.append("words", JSON.stringify(extractableText));
      formData.append("schedule", schedule);
      formData.append("scheduled_datetime", selectedDateTime);

      axios
        .post(
          "https://1074-103-121-27-178.ngrok-free.app/api/invoice/bulk-upload-batch-processing",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
            processData: false,
            contentType: false,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            navigate("/dashboard")
          }
        })
        .catch((err) => {
          setAlertBox(true);
          setMessage(err.message);
          // console.log(alertBox);
        });
    }
  };

  return (
    <div className="bg-primaryLight h-screen overflow-hidden mb-4">
      {alertBox ? <AlertMessage message={Message} onCancel={onCancel} /> : ""}
      <Header showProfile={true} profileName={localStorage.getItem("user")} />
      <ToastContainer />
      <div className="py-8 px-14 w-full h-[calc(100%-100px)] overflow-auto max-w-full">
        <div className=" max-w-[1254px] ml-auto mr-auto">
          <div className="mb-8">
            <h2
              className="text-black100 font-bold text-2xl leading-[29px] mb-2"
              style={{ fontSize: "2.4rem" }}
            >
              Invoice Insights Generator
            </h2>
            <p
              className="font-normal text-base text-grey100 mt-4"
              style={{ fontSize: "1.6rem" }}
            >
              Perform text extraction on batch of invoices and apply custom
              business rules
            </p>
          </div>
          <div className="w-full h-full min-h-[110px] p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2
              className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]"
              style={{ fontSize: "1.6rem" }}
            >
              Select Service
            </h2>
            <div className="w-[285px] p-2 bg-red-100 rounded-lg shadow-shadow2">
              <div
                className="btn-group btn-group-toggle w-full custom-buttons"
                data-toggle="buttons"
              >
                <label
                  className="btn btn-primary w-full bg-none border-none"
                  style={{ fontSize: "1.4rem" }}
                  onClick={() => handleServiceChange("textract")}
                >
                  <input
                    type="radio"
                    name="option1"
                    id="textract"
                    value="textract"
                  />{" "}
                  Textract
                </label>
                <label
                  className="btn btn-primary active w-full bg-none border-none"
                  style={{ fontSize: "1.4rem" }}
                  onClick={() => handleServiceChange("custom")}
                >
                  <input
                    type="radio"
                    name="option1"
                    id="custom"
                    value="custom"
                  />{" "}
                  Custom
                </label>
              </div>
            </div>
          </div>
          <div className="w-full h-full min-h-[110px] p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2
              className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]"
              style={{ fontSize: "1.6rem" }}
            >
              Upload Invoice
            </h2>
            <div className="flex items-center">
              <div className="bg-white border border-solid border-border100 p-4 rounded shadow-cardShadow flex items-center">
                <div className="flex items-center">
                  <icons.UploadIcon />
                  <h2
                    className="text-base leading-6 font-medium text-gray200 ml-2"
                    style={{ fontSize: "1.4rem" }}
                  >
                    Upload Invoice -
                  </h2>
                </div>
                <div className="ml-1">
                  <label
                    htmlFor="customFile"
                    className="mb-0 cursor-pointer border border-solid !border-red200 p-2 leading-[17px] text-red200 font-semibold rounded"
                  >
                    Choose File
                  </label>
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.jpeg,.png,.jpg"
                    onChange={handleFileChange}
                    onClick={clearFile}
                    id="customFile"
                    className="hidden"
                  />
                  <button
                    className={`mb-0 cursor-pointer border border-solid !border-red200 p-2 ml-2 leading-[17px] text-red200 font-semibold rounded ${
                      !readyForUpload ? "opacity-50" : ""
                    }`}
                    onClick={handleFileSubmit}
                    disabled={!readyForUpload}
                  >
                    Upload File
                  </button>
                </div>
              </div>

              <div className="flex items-center ml-4">
                {progress && <Spin tip={`Uploading...`} />}
                {upload && <icons.PdfIcon />}
              </div>
            </div>
          </div>
          <div className="w-full h-full min-h-[110px] p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2
              className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]"
              style={{ fontSize: "1.6rem" }}
            >
              Exclude Line Item
            </h2>
            <Button
              type="primary"
              onClick={openModal2}
              className="bg-white py-2 px-3 flex items-center h-full shadow-none border border-solid !border-red200 text-xs leading-6 font-medium text-red200  rounded-lg hover:!text-red200 focus:!outline-none "
            >
              <icons.AddIcon />
              <h2 className="ml-2" style={{ fontSize: "1.2rem" }}>
                ADD ITEM
              </h2>
            </Button>

            <ModalTextInput
              isOpen={modal2Open}
              onClose={closeModal2}
              onSave={handleExtractModalSave}
            />

            {Object.keys(extractableText).length !== 0 ? (
              <div className="flex items-center w-full flex-wrap gap-2 mt-[16px]">
                {Object.entries(extractableText).map(([name, value], index) => (
                  <div
                    key={index}
                    className="border border-solid !border-border300 bg-red500 rounded-[30px] flex items-center py-1.5 px-4" 
                  >
                    <h2 className="font-medium text-xl leading-6 text-black100 mr-2 pt-[2px]">
                      {name}
                    </h2>
                    <Button
                      type="danger"
                      onClick={() => handleRemoveWord(name)}
                      className="delete-button border-none w-10 h-10 p-0 ml-3.5 focus:outline-none"
                    >
                      <icons.TrashIcon />
                    </Button>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="w-full h-full min-h-[110px] p-6 bg-white border-border100 border border-solid rounded-xl shadow-cardShadow mb-[16px]">
            <h2
              className="font-semibold text-lg leading-5.5 text-black100 mb-[16px]"
              style={{ fontSize: "1.6rem" }}
            >
              Schedule Job
            </h2>
            <Radio.Group
              onChange={handleScheduleChange}
              defaultValue={false}
              value={schedule}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
            {schedule === true && (
              <DatePicker
                showTime
                onChange={handleDateTimeChange}
                disabledDate={disabledDate}
              />
            )}
          </div>

          <div className="pt-8 pb-8 bg-primaryLight fixed bottom-0 w-full left-0">
            <div className="max-w-[1254px] ml-auto mr-auto pr-[17px]">
              <button
                type="submit"
                id="submit-button"
                className={`w-[190px] py-4 bg-red200 rounded-lg text-white leading-[19px] font-semibold h-[51px] flex items-center justify-center float-right submit-button ${
                  !upload ? "opacity-50" : ""
                }`}
                onClick={handleSubmit}
                disabled={!upload}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddInvoiceView;
