import React from "react";

const PlainLayout = ({ children, profileName, showProfile }) => {
  return (
    <div className="h-screen overflow-hidden" id="plainlayout">
      <div className="app-l-page-wrap">
        {children}
      </div>
    </div>
  );
};

export default PlainLayout;
