import React from "react";
import { useNavigate } from "react-router-dom";
import AbcisLogo from "../assets/images/abcisLogo.svg";
import ExperionLogoDark from "../assets/images/experionLogoDark.svg";
import BannerGraphics from "../assets/images/banner.svg";
import PlainLayout from "../layouts/PlainLayout";
import icons from "../assets/icons";
const HomeView = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  const handleExperionLogoClick = () => {
    window.location.href = "https://experionglobal.com/";
  };

  return (
    <PlainLayout>
      <div className="app-l-content-wrap gradient-bg">
        <div className="app-c-header">
          <div className="app-c-header__left">
            <h1 className="app-c-header__logo">
              <a href="#">
                <img src={AbcisLogo} alt="ABCis" />
              </a>
            </h1>
          </div>
        </div>
        <div className="app-l-banner__wrap">
          <div className="app-l-banner">
            <div className="app-l-banner__left">
              <h2 className="app-l-banner__heading">Invoice Data Extraction</h2>
              <p className="app-l-banner__desc">
                Easily extract and organize invoice data by currency and export
                it as Excel spreadsheets
              </p>
              <button
                className="app-c-btn app-c-btn--primary"
                onClick={handleClick}
              >
                Try Demo
                <icons.ArrowRight />
              </button>
            </div>
            <div className="app-l-banner__right">
              <img src={BannerGraphics} alt="ABCis" />
            </div>
          </div>
        </div>

        <div className="app-c-footer">
          <div className="app-c-footer__right">
            <span className="app-c-footer__powered-by-text">Powered by</span>
            <img
              src={ExperionLogoDark}
              alt="Experion"
              onClick={handleExperionLogoClick}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </PlainLayout>
  );
};
export default HomeView;
